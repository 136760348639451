import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    DateTimePicker,
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {formatDateToTimeZone} from "../../../utils/formatters";

export default function DatetimePicker({ label, value, handleChange }) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
                variant="inline"
                margin="normal"
                ampm={false}
                label={label}
                value={formatDateToTimeZone(value, "MM/dd/yyyy HH:mm")}
                onChange={handleChange}
                onError={console.log}
                format="MM/dd/yyyy HH:mm"
            />
        </MuiPickersUtilsProvider>
    );
}
