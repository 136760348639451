import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import OpportunityCard from "../cards/OpportunityCard";
import DrawingCard from "../cards/DrawingCard";
import RankingCard from "../cards/RankingCard";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    height: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },

  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "red",
  },
}));

export default function AgencyDashboard() {
  const [org, setOrg] = useState({});
  const [oppStats, setOppStats] = useState({});
  const [drawingStats, setDrawingStats] = useState({});
  const [rankingStats, setRankingStats] = useState({});

  const history = useHistory();
  const classes = useStyles();

  async function fetchCounts() {
    const [opportunities, drawings, rankings] = await Promise.all([
      fetchOppCounts(),
      fetchDrawingCounts(),
      fetchRankingCounts(),
    ]);

    setOppStats(
      opportunities?.data?.data ?? {
        NumOpportunitiesCount: 0,
        NumAvailableCount: 0,
        ExtremelyLowCount: 0,
        VeryLowCount: 0,
        LowCount: 0,
        ModerateCount: 0,
        MedianCount: 0,
      }
    );
    setDrawingStats(
      drawings?.data?.data ?? {
        NumOpportunitiesCount: 0,
        OpenDrawingsCount: 0,
        NumTotalApplicantsCount: 0,
        NumActiveApplicantsCount: 0,
        PublishedCount: 0,
      }
    );
    setRankingStats(
      rankings?.data?.data ?? {
        NumRankingsCount: 0,
        NumOpportunitiesCount: 0,
        OpenRankingsCount: 0,
        NumTotalApplicantsCount: 0,
        NumActiveApplicantsCount: 0,
        PublishedCount: 0,
      }
    );
  }

  useEffect(() => {
    (async () => {
      await fetchCounts();
      //axios.get(`${getBaseUrl()}/auth-test`);
    })();
  }, []);

  return (
    <section className="flex flex-wrap justify-center">
      <DrawingCard
        clickHandler={() => history.push("/drawings")}
        drawingStats={drawingStats}
        classes={classes}
      />

      <OpportunityCard
        clickHanlder={() => history.push("/opportunities")}
        orgName={org.EntityName}
        oppStats={oppStats}
      />

      <RankingCard
        clickHandler={() => history.push("/rankings")}
        rankingStats={rankingStats}
        classes={classes}
      />
    </section>
  );

  async function fetchOppCounts() {
    return axios.get(`${getBaseUrl()}/opportunities/cnt`);
  }

  async function fetchDrawingCounts() {
    return axios.get(`${getBaseUrl()}/drawings/cnt`);
  }

  async function fetchRankingCounts() {
    return axios.get(`${getBaseUrl()}/rankings/cnt`);
  }
}
