import React, { useReducer } from "react";

export default function useOpportunityFilters() {
  const [state, dispatch] = useReducer(filterReducer, {
    opportunities: [],
    incomeLevel: "",
    numBeds: "",
    propertyType: "",
    loadingOpportunites: false,
    filteredOpps: [],
    oppStatus: "",
    msa: "",
  });

  return [state, dispatch];
}

function filterReducer(state, action) {
  switch (action.type) {
    case "opps":
      return {
        ...state,
        opportunities: action.payload,
        filteredOpps: action.payload,
      };
    case "propertyType":
      return {
        ...state,
        propertyType: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.OpportunityType === action.payload
              ),
      };
    case "msa":
      return {
        ...state,
        msa: action.payload,
        filteredOpps: state.opportunities.filter(
          (o) => o.OpportunityType === action.payload
        ),
      };
    case "incomeLevel":
      return {
        ...state,
        incomeLevel: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.CandidateIncomeLevel === action.payload
              ),
      };
    case "numBeds":
      return {
        ...state,
        numBeds: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.BedRoomCnt === action.payload
              ),
      };
    case "loadingOpps":
      return {
        state,
        loadingOpportunites: action.payload,
      };

    case "oppStatus":
      return {
        ...state,
        oppStatus: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.OpportunityStatus === action.payload
              ),
      };

    default:
      return state;
  }
}
